<template>
    <div class="ks-emails ks-compact d-flex ">
        <div class="ks-header" v-if="!show">
            <div class="ks-search">
                <div class="input-icon icon-right icon icon-lg">
                    <input id="input-group-icon-text" type="text" class="form-control" placeholder="Search Quote number ..." ref="search" autocomplete="off" v-model="data.search" @keyup.enter="retreiveQuote">
                    <span class="icon-addon">
                        <span class="la la-search"></span>
                    </span>
                </div>
            </div>
           <div class="ks-actions">
                <div class="input-icon icon-right icon icon-lg">
                    <input type="date" class="form-control" v-model="data.dateFrom" @change="retreiveQuote">
                </div>
            </div>
            <div class="ks-actions">
                <div class="input-icon icon-right icon icon-lg" >
                    TO
                </div>
            </div>
            <div class="ks-actions">
                <div class="input-icon icon-right icon icon-lg">
                    <input type="date" class="form-control" v-model="data.dateTo" @change="retreiveQuote">
                </div>
            </div>
              <div class="ks-actions">
                <div class="input-icon icon-right icon icon-lg">
                    <button class="btn btn-secondary" @click="retreive(true)">Refresh</button>
                </div>
            </div>
        </div>
        <div class="ks-header" v-else>
            <h1>Quote Details</h1>
        </div>
         <QTtable :quotation="quote" :isloading="isloading" />
       
    </div>

</template>

<script>
import QTtable from '@/views/quotation/qt_table.vue' 


export default {
    components:{
        QTtable
    },

    data(){
     return {
            quote : {},
            data : { },
            isloading : false,
            show: false
        }   
     },
   methods : {
       async retreive(refresh = false){
            if(refresh){
                this.data = {}
                this.quote = {}
            }
            this.data.page = 1

            // var prev = this.API._initDate(0)
            // var next = this.API._initDate(2)

            var prev = this.$moment().subtract(30,'day').format('YYYY-MM-DD');
            var next =  this.$moment().add(30,'day').format('YYYY-MM-DD');
            this.data.dateTo = next
            this.data.dateFrom = prev
            // console.log(next)
            this.retreiveQuote()
          
        
            // console.log(this.data.dateTo + ' ' + this.data.dateFrom)
        },
        async retreiveQuote(){
            this.quote = {}
            this.isloading = !this.isloading
            let response = await this.API.retrieveQuote(this.data)
            let quotation = []
            if(response.quotation != false){

                response.quotation.forEach(element => {
                  
                    quotation.push(JSON.parse(element))
                 
                });

            }

            this.quote = response ;
            this.quote.quotation = quotation;

              this.isloading = !this.isloading

            //   console.log(this.quote)
  
        },
        showDetails(){
            if(this.show == false){
            this.show = true
            }
            else {
                this.show = false
            }
        }

        // change(){
        //     console.log(this.data.dateTo + ' ' + this.data.dateFrom)
        // }
    },
    mounted(){
        let instance = this
         this.$emitter.on('next', function(){
            instance.data.page ++
            instance.retreiveQuote()
        })
        this.$emitter.on('prev', function(){
            instance.data.page --
            instance.retreiveQuote()
        })
        this.$emitter.on('select', function(data){
            instance.data.page = data
            instance.retreiveQuote()
        })
        this.$emitter.on('reload', function(){
            instance.retreive(true)
        })
        this.$emitter.on('showDet', function(){
            instance.showDetails()
        })
        this.$emitter.on('close', function(){
            instance.showDetails()
            instance.retreiveQuote()

        })
        this.retreive()
        //   console.log(this.quote)
     },
   
}
</script>
<style scoped>
@media only screen and (max-width: 890px) {

    .ks-actions {
       display: none !important;
    }
}
</style>

<template>
        <div class="d-flex flex-column m-3">
            <div class="d-flex flex-column web">
                <div class="d-flex flex-column">
                    <div class="d-flex justify-content-between w-100 bg-light p-2">
                        <span class="d-flex">
                            <h4 for="" class="mr-2">Quote Number :</h4>
                            <h4 class="red"> {{selected_data.quote_number}}</h4>
                        </span>
                        <span class="d-flex ">
                            <h4 class="mr-2">Quote Type :</h4>
                                <h4 class="grey">{{selected_data.quote_type_desc}}</h4>
                        </span>
                        <span class="d-flex ">
                            <h4 class="mr-2">Term :</h4>
                            <h4 class="grey">{{selected_data.TERM_DESCRIPTION}}</h4>
                        </span>
                        <span class="d-flex">
                            <h4 for="" class="mr-2">Quote Date :</h4>
                            <h4 class="grey">{{selected_data.quote_date}}</h4>
                        </span>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-between">

                        <!--1-->
                         <ul class="list-group d-flex list-group-flush ">
                            <li class="list-group-item  d-flex">
                                <span class="">
                                    <strong for="" class="">Product</strong>
                                    <h5 class="grey">{{selected_data.product_group_name}}</h5>
                                </span>
                            </li>
                            <li class="list-group-item d-flex">
                                <span class="">
                                    <strong for="" class="">Salesman</strong>
                                    <h5 class="grey">{{selected_data.salesman_name}}</h5>
                                </span>
                            </li>
                            <li class="list-group-item d-flex ">
                                <span class="">
                                    <strong for="" class="">PO #</strong>
                                    <h5 class="grey">{{selected_data.po_number}}</h5>
                                </span>
                            </li>
                               <li class="list-group-item  ">
                                 <span class="">
                                    <strong for="" class="">PO Date</strong>
                                    <h5 class="grey">{{selected_data.po_date}}</h5>
                                </span>
                            </li>
                         </ul>
                          <!--2-->
                        <ul class="list-group d-flex list-group-flush ">
                            <li class="list-group-item d-flex ">
                                <span class="">
                                    <strong for="" class="">Customer</strong>
                                    <h5 class="grey">{{selected_data.customer_name}}</h5>
                                </span>
                            </li>
                            <li class="list-group-item d-flex ">
                                <span class="">
                                    <strong for="" class="">Customer Contact Person</strong>
                                    <h5 class="grey">{{selected_data.cutomer_contact_person}}</h5>
                                </span>
                            </li>
                            <li class="list-group-item  d-flex ">
                                <span class=" ">
                                    <strong for="" class="">End User</strong>
                                    <h5 class="grey">{{selected_data.end_user_name}}</h5>
                                </span>
                            </li>
                               <li class="list-group-item  d-flex">
                                 <span class="">
                                    <strong for="" class="">End User Contact Person</strong>
                                    <h5 class="grey">{{selected_data.eu_contact_person}}</h5>
                                </span>
                            </li>
                         </ul>
                         <!--3-->
                        <ul class="list-group d-flex list-group-flush ">
                            <li class="list-group-item  d-flex ">
                                <span class=" ">
                                    <strong for="" class="">Total Sell USD</strong>
                                    <h5 class="red">{{selected_data.total_selling_price$}}</h5>
                                </span>
                            </li>
                            <li class="list-group-item  d-flex ">
                                <span class=" ">
                                    <strong for="" class="">Total Buy USD</strong>
                                    <h5 class="red">{{selected_data.total_buy_price$}}</h5>
                                </span>
                            </li>
                            <li class="list-group-item d-flex">
                                <span class=" ">
                                        <strong for="" class="">GP Amount USD</strong>
                                    <h5 class="red">{{selected_data.gp_amount$}}</h5>
                                    
                                </span>
                            </li>
                            <li class="list-group-item  d-flex  ">
                                    <span class=" ">
                                          <strong for="" class="">GP Percentage</strong>
                                        <h5 class="red">{{selected_data.gp_perc}}%</h5>
                                 
                                    </span>
                            </li>
                         </ul>
                       <!--4-->
                        <ul class="list-group d-flex list-group-flush ">
                            <li class="list-group-item  d-flex">
                                 <span class=" ">
                                        <strong for="" class="">Total Sell PHP</strong>
                                    <h5 class="red">{{selected_data.total_selling_price}}</h5>
                                </span>
                            </li>
                            <li class="list-group-item  d-flex">
                                <span class=" ">
                                     <strong for="" class="">Total Buy PHP</strong>
                                        <h5 class="red">{{selected_data.total_buy_price}}</h5>
                                </span>
                            </li>
                            <li class="list-group-item  d-flex  ">
                                <span class=" ">
                                    <strong for="" class="">GP Amount PHP</strong>
                                    <h5 class="red">{{selected_data.gp_amount}}</h5>
                                </span>
                            </li>
                             <li class="list-group-item  d-flex">
                                <span class="">
                                   <strong for="" class="">Allowed GP</strong>
                                    <h5 class="red">{{selected_data.allowed_gp}}%</h5>
                                </span>
                            </li>
                            
                            
                         </ul>
                        <!--5-->
                        <ul class="list-group d-flex list-group-flush ">
                            <li class="list-group-item d-flex">
                                <span class="">
                                    <strong for="" class="">Forex</strong>
                                    <h5 class="red">{{selected_data.forex}}</h5>
                                </span>
                            </li>
                            <li class="list-group-item d-flex  ">
                                <span class=" ">
                                    <strong>Incidental Cost</strong>
                                    <h5 class="red">{{selected_data.incidental_cost}}</h5>
                                </span>
                            </li>
                            <!-- <li class="list-group-item justify-content-center d-flex bg-light py-2">
                                <span class="bg-light p-2">
                                    <h5 for="" class="mr-2">Total Buy USD</h5>
                                    <h5 class="grey">{{selected_data.total_buy_price$}}</h5>
                                </span>
                            </li>
                               <li class="list-group-item justify-content-center d-flex bg-light py-2">
                                 <span class="bg-light p-2">
                                    <h5 for="" class="mr-2">Total Sell USD</h5>
                                    <h5 class="grey">{{selected_data.total_selling_price$}}</h5>
                                </span>
                            </li> -->
                         </ul>
            
            

                    </div>


        
                </div>
                <hr>
                <div class="mb-3">
                        <ul class="list-group d-flex list-group-flush" v-if="selected_data.reason">
                                <h4>Reason for approval</h4>
                        
                            <li class="list-group-item justify-content-center d-flex">
                                <strong class="d-block fw-500" style="max-width: 60%;"><h4 class="reasons">{{selected_data.reason}}</h4></strong>
                            </li>
                        </ul>
                 </div>
                <div class="d-flex flex-column">
                    
                    <hr>
                    <div v-if="selected_data.incidental_cost > 0" class="mt-4">
                        <table class="ks-table table table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        Trans #
                                    </th>
                                    <th>
                                        Type
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Amount Dollar
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="h-100">
                                <tr v-for="(gpc,index) in selected_data.TGPC.result" :key="index">
                                    <td>
                                        {{gpc.trans_no}}
                                    </td>
                                    <td>
                                        {{gpc.type}}
                                    </td>
                                    <td>
                                        {{gpc.description}}
                                    </td>
                                    <td>
                                        {{gpc.amount}}
                                    </td>
                                    <td>
                                        {{gpc.amount_dol}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>

                </div>
            </div>
            <div  class="flex-column mobile scroll">
                <ul class="list-group d-flex list-group-flush ">
                        <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Quote #</h4>
                            <span class="d-block fw-500 ml-auto" style="max-width: 60%;"><h4 class="red"> {{selected_data.quote_number}}</h4></span>
                        </li>
                        <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Quote Type</h4>
                            <span class="d-block fw-500 ml-auto" style="max-width: 60%;"> {{selected_data.quote_type_desc}}</span>
                        </li>
                        <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Term</h4>
                            <span class="d-block fw-500 ml-auto" style="max-width: 60%;" >{{selected_data.TERM_DESCRIPTION}}</span>
                        </li>
                        <li class="list-group-item d-flex mr-2 py-2">
                            <h4>Quote Date</h4>
                            <span class="d-block fw-500 ml-auto" style="max-width: 60%;"> {{selected_data.quote_date}}</span>
                        </li>
                        <li class="list-group-item d-flex bg-light mr-2 py-2">
                            
                        </li>
                        <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Salesman</h4>
                            <span class="d-block fw-500 ml-auto" style="max-width: 60%;"> {{selected_data.salesman_name}}</span>
                        </li>
                        <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Customer</h4>
                            <span class="d-block fw-500 ml-auto" style="max-width: 60%;"> {{selected_data.customer_name}}</span>
                        </li>
                        <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Customer Contact Person</h4>
                            <span class="d-block fw-500 ml-auto" style="max-width: 60%;"> {{selected_data.cutomer_contact_person}}</span>
                        </li>
                         <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>End User</h4>
                            <span class="d-block fw-500 ml-auto" style="max-width: 60%;">{{selected_data.end_user_name}}</span>
                        </li>
                        <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>End User Contact Person</h4>
                            <span class="d-block fw-500 ml-auto" style="max-width: 60%;">{{selected_data.eu_contact_person}}</span>
                        </li>
                          <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Product</h4>
                            <span class="d-block fw-500 ml-auto" style="max-width: 60%;">{{selected_data.product_group_name}}</span>
                        </li>
                        <li class="list-group-item d-flex bg-light mr-2 py-2">
                            
                        </li>
                         <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>PO Number</h4>
                            <span class="d-block fw-500 ml-auto" style="max-width: 60%;">{{selected_data.po_number}}</span>
                        </li>
                        
                         <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>PO Date</h4>
                            <span class="d-block fw-500 ml-auto" style="max-width: 60%;"> {{selected_data.po_date}}</span>
                        </li>
                         <li class="list-group-item d-flex bg-light mr-2 py-2">
                            
                        </li>
                         <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Forex</h4>
                            <span class="d-block fw-500 ml-auto red" style="max-width: 60%;"> {{selected_data.forex}}</span>
                        </li>
                           <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Allowed GP</h4>
                            <span class="d-block fw-500 ml-auto red" style="max-width: 60%;"> {{selected_data.allowed_gp}}%</span>
                        </li>
                           <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>GP Amount PHP</h4>
                            <span class="d-block fw-500 ml-auto red" style="max-width: 60%;">{{selected_data.gp_amount}}</span>
                        </li>
                           <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>GP Amount USD</h4>
                            <span class="d-block fw-500 ml-auto red" style="max-width: 60%;">{{selected_data.gp_amount$}}</span>
                        </li>
                        <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>GP Percentage</h4>
                            <span class="d-block fw-500 ml-auto red" style="max-width: 60%;">{{selected_data.gp_perc}}%</span>
                        </li>
                        <li class="list-group-item d-flex bg-light mr-2 py-2">
                            
                        </li>
                        <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Total Sell PHP</h4>
                            <span class="d-block fw-500 ml-auto red" style="max-width: 60%;">{{selected_data.total_selling_price}}</span>
                        </li>
                            <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Total Sell USD</h4>
                            <span class="d-block fw-500 ml-auto red" style="max-width: 60%;">{{selected_data.total_selling_price$}}</span>
                        </li>
                        <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Total Buy PHP</h4>
                            <span class="d-block fw-500 ml-auto red" style="max-width: 60%;">{{selected_data.total_buy_price}}</span>
                        </li>
                         <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Total Buy USD</h4>
                            <span class="d-block fw-500 ml-auto red" style="max-width: 60%;">{{selected_data.total_buy_price$}}</span>
                        </li>
                        <li class="list-group-item d-flex  mr-2 py-2">
                            <h4>Incidental Cost</h4>
                            <span class="d-block fw-500 ml-auto red" style="max-width: 60%;">{{selected_data.incidental_cost}}</span>
                        </li>
                        <li class="list-group-item d-flex bg-light mr-2 py-2">
                            <h4>Reason for approval</h4>
                        </li>
                        <li class="list-group-item d-flex justify-content-center py-2">
                             <span class="d-block fw-500 ml-auto" style="max-width: 60%;">{{selected_data.reason}}</span>
                        </li>
                       


                </ul>
            </div>
          
          
        </div>
</template>

<script>
export default {
    props: ['show','selected_data'],
    data() {
        return {
        
            loading: false,
            data: {},
            status: this.$route.name,
            me : {}
      
        }
    },
    computed : {
        checkApproved(){
            if(this.selected_data.approved_by == null){
                return true
            }
            else {
                return false
            }
        },
        isAdmin(){
            let response
            switch(this.me.crf_users){
                case 'APV' :
                case 'PTR' : 
                    response = true
                break
                default : 
                     response = false
                break
            }

            return response
        }
    },
    methods: {
        open() {
            let body = document.body;
            if(body.classList.contains('ks-right-bar-open')) {
                this.data = {};
                body.classList.remove('ks-right-bar-open');
            }
            else body.classList.add('ks-right-bar-open');

           
        },
        async modalMe(){
            this.me = await this.API.ME()
            
        
        },
        Edit(data){
      
            this.open()
            this.$emitter.emit('edit',data)
        },
      async approve(){
             this.loading = !this.loading
            // this.$emitter.emit('approved')
             await this.API.approveQoute(this.selected_data).
             then(res => {
                
                this.open()
                console.log(res)

                this.$jlalert.show(res.data, res.status != 200)
                this.$emitter.emit('approved')

                this.loading = !this.loading

                
             })
             .catch(err => {
                console.log(err)

                this.$jlalert.show(err)
             })

        }
    },
    mounted() {
          this.modalMe()

    },
    watch: {   
        "show": function(n) {
            if(n === false) this.data = {};
            this.open();
            // console.log(this.selected_data)
        },
    }
}
</script>

<style scoped>
label {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    opacity: 0.8;
}

.dropdown .dropdown-menu {
    border: solid 1px #e6e6e6;
}

.dropdown .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #f9f9f9;
}
td,th {
    text-align: center;
}
.red{
    color: red;
}
.grey{
    color: grey;
}
.yellow {
    color: yellow;
}
.list-group-item {
    border-top: 1px rgba(0,0,0, 0.05);
    border-bottom: 1px rgba(255, 255, 255,0.05);
}

.list-group-item:hover{
    background: wheat;
}
/* h4, .sub {
    width: 100%;
} */
.reasons{
    text-transform: uppercase;
}
.mobile {
    display: none  !important;
}
@media only screen and (max-width: 890px) {
    .mobile {
        display: flex !important;
    }
    .web {
        display: none !important;
    }

}
</style>
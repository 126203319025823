<template>
    <div class="ks-body position-relative flex-1">
        <div class="scroll" >
            <table class="ks-table table table-hover" v-if="!show">
                <thead>
                    <th>
                        #
                    </th>
                   <th>
                       Quote #
                   </th>
                    <th>
                       Quote Date
                   </th>
                   <th>
                        Product
                   </th>
                    <th>
                       Customer
                   </th>
                    <th>
                        Salesman
                   </th>
                </thead>
                <tbody v-if="isloading">
                    <tr>
                       <td colspan="6" class="text-center">
                             <span class="spinner-border spinner-border-sm"></span>
                            Loading...
                        </td> 
                    </tr>
                </tbody>
                <tbody v-if="!quotation.quotation && !isloading"> 
                    <tr>
                        <td colspan="6" class="text-center">
                            No data found
                        </td>
                    </tr>
                </tbody>
                 <tbody v-else> 
                    <tr v-for="(quote,index) in quotation.quotation" :key="index" @click="qtDetail(index)">
                        <td>
                           {{quote.RowNum}}
                        </td>
                        <td>
                            {{quote.quote_number}}
                        </td>
                        <td>
                            {{quote.quote_date}}
                        </td>
                        <td>
                            {{quote.product_group_name}}
                        </td>
                        <td>
                            {{quote.customer_name}}
                        </td>
                        <td>
                            {{quote.salesman_name}}
                        </td>
                    </tr>
                </tbody>
               
            </table>
      <qtModal :show="show" :selected_data="selected_data" v-else/>

        </div>


    </div>
    <div class="ks-header ks-footer">
    <div v-if="!show">

        <span class="text-bold mr-5">
            <b>{{quotation.total}}</b> record(s) 
        </span>
        <span class="mx-3" v-if="quotation">
            <ul class="pagination ">
                <li class="page-item" >
                    <a class="btn btn-secondary-sm border-0" :class="(quotation.page <= 1 ? 'disabled': isloading == true ? 'disabled'  : '')"  @click="changePage('p')">  <i class="la la-angle-double-left"></i> Prev </a>
                </li>
                <li class="page-item mx-5 d-flex flex-row">
                    <!-- <a class="btn btn-secondary-sm border-0">    <b>{{crf.page}}</b> / <b>{{crf.total_page}}</b> </a> -->
                        <select :disabled="!quotation.total_page && !quotation.total || isloading" class="form-select border-0" v-model="page" @change="changePage('s')">
                            <option :value="p" v-for="p in getpages" :key="p" > <b>{{p}} / {{quotation.total_page}}</b> </option>
                            
                    </select>

                    
                </li>
                <li class="page-item">
                    <a class="btn btn-secondary-sm border-0" :class="(quotation.page >= quotation.total_page ? 'disabled' : isloading == true ? 'disabled' : '')"  @click="changePage('n')" >  Next  <i class="la la-angle-double-right"></i> </a>
                </li>
            </ul>
        </span>
    </div>
    <div v-else class=" d-flex justify-content-between w-100">

        <button class="btn btn-success" @click="approved()"  v-if="!loading">Approve</button>

      <button class="btn btn-success" type="button" disabled v-else>
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Processing...
        </button>

        <button class="btn btn-secondary" @click="close()">Close</button>
    </div>

    </div>

</template>
<script>
import qtModal from '@/views/quotation/qt_modal.vue'
export default {
    props:['quotation', 'isloading'],
    components:{
        qtModal
    },
    data() {
        return {
            page : 1,
            show : false,
            selected_data : {},
            me: {},
            loading : false
        }
    },

    computed:{
        getpages(){
            let pages = [];
            if(this.quotation.total_page) {
                for (let index = 1; index <= this.quotation.total_page; index++) {
                    pages.push(index);
                }
            }
            return pages;
        }
    },
    methods: {

        async Me(){
            this.me = await this.API.ME()
        },
        qtDetail(index){
            this.show = !this.show;
            this.selected_data = this.quotation.quotation[index]
     
            this.changePage('showDet')
        },
        async approved(){    
            this.loading != this.loading        
             await this.API.approveQoute(this.selected_data).
             then(res => {
                
                this.$jlalert.show(res.data,res.status != 200)
                this.show = !this.show
                this.changePage('close')

                this.loading != this.loading        

             })
             .catch(err => {
                this.$jlalert.show(err)
             })

        },
        changePage(type){
            switch(type){
                case 'n':
                    this.$emitter.emit('next')
                    this.page ++
                break
                case 'p':
                    this.$emitter.emit('prev')
                    this.page --
                break
                case 's':
                    this.$emitter.emit('selected',this.page)
                break
                case 'showDet':
                    this.$emitter.emit('showDet')  
                break 
                case 'close':
                    this.$emitter.emit('close')
                break

            }
        },
        close(){
            this.show = !this.show
            this.changePage('showDet')
        }
    },
    mounted(){
        let instance = this
        this.$emitter.on('approved', function(){
           instance.approved()
        })

      
        this.Me()
    },
}
</script>